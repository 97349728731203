<template>
  <div class="model-box" ref="model-box">
    <div class="information">
      <div class="information-left">
        <div class="information-left-text">设备数量</div>
        <div class="information-left-num">{{ options.deviceNumber || '--' }} <span>台</span></div>
      </div>
      <div class="information-right">
        <div class="information-right-text">资产价值</div>
        <div class="information-right-num">{{ options.deviceAssetValue || '--' }} <span>元</span></div>
      </div>
    </div>
    <div class="equipment">
      <div class="equipment-item" v-for="item,index in (options.deviceModelsNumber || [])" :key="index">
        <img class="equipment-item-icon" v-if="item.deviceImageUrl" :src="item.deviceImageUrl" alt="">
        <img class="equipment-item-icon" v-else src="../../../../assets/images/bigScreen/customerRecord/excavator.png" alt="">
        <div class="equipment-item-content">
          <div class="equipment-item-content-num">{{ item.deviceNumber || '--' }}台</div>
          <div class="equipment-item-content-text">{{ item.deviceName || '--' }}</div>
        </div>
      </div>
      <!-- <div class="equipment-item">
        <img class="equipment-item-icon" src="../../../../assets/images/bigScreen/customerRecord/crane.png" alt="">
        <div class="equipment-item-content">
          <div class="equipment-item-content-num">222台</div>
          <div class="equipment-item-content-text">起重机</div>
        </div>
      </div>
      <div class="equipment-item">
        <img class="equipment-item-icon" src="../../../../assets/images/bigScreen/customerRecord/stir.png" alt="">
        <div class="equipment-item-content">
          <div class="equipment-item-content-num">222台</div>
          <div class="equipment-item-content-text">搅拌车</div>
        </div>
      </div> -->
    </div>
    <div ref="seamless" style="flex: 1;overflow: hidden;">
      <vue-seamless-scroll :data="options.devicePriceListInformation"
        :style="{ width: '100%',height: 'calc(100%)', overflow: 'hidden' }"
          :class-option="defaultOption">
          <div class="scheme-box-item" v-for="item,index in options.devicePriceListInformation" :key="index">
            <div class="scheme-box-item-flex">{{ item.deviceName || '--' }}</div>
            <div class="scheme-box-item-flex">{{ item.deviceAcquisitionPrice || '--' }}</div>
            <div class="scheme-box-item-right">{{ item.purchasingMethod == 1 ? '融资' : '全款' || '--' }}</div>
          </div>
        </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  components: {
    vueSeamlessScroll
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      situationoptions: [{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      boxH: 0
    };
  },
  created() {
    //  this.init();
    this.$nextTick(() => {
      this.boxH = this.$refs["seamless"].offsetHeight - (this.$refs["model-box"].offsetHeight * 0.02);
      console.log(this.boxH ,this.$refs["model-box"].offsetHeight, this.$refs["seamless"].offsetHeight);
    });
  },
}
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  flex-direction: column;
  height: 88%;
  padding: 3%;
  overflow: hidden;
  .information {
    display: flex;
    justify-content: space-between;
    height: 36%;
    padding: 0 2%;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 48%;
      height: 100%;
      padding: 0.16rem;
      background-image: url('../../../../assets/images/bigScreen/customerRecord/quantity.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        font-size: 0.14rem;
        color: #fff;
      }
      &-num {
        font-size: 0.22rem;
        color: #18D2CE;
        font-family: 'ZhanKuQingKeHuangYouTi';
        span {
          font-family: auto;
          font-size: 0.14rem;
          color: #fff;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 48%;
      height: 100%;
      padding: 0.16rem;
      background-image: url('../../../../assets/images/bigScreen/customerRecord/quantity.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        font-size: 0.14rem;
        color: #fff;
      }
      &-num {
        font-size: 0.22rem;
        color: #1560EA;
        font-family: 'ZhanKuQingKeHuangYouTi';
        span {
          font-family: auto;
          font-size: 0.14rem;
          color: #fff;
        }
      }
    }
  }
  .equipment{
    display: flex;
    justify-content: space-between;
    &-item {
      flex: 1;
      display: flex;
      &-content{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0.12rem 0;
        &-num {
          font-size: 0.18rem;
          color: #18D2CE;
        }
        &-text {
          font-size: 0.14rem;
          color: #fff;
        }
      }
      &-icon {
        height: 0.77rem;
        margin-right: 0.02rem;
      }
    }
  }
  .scheme-box {
    padding: 1%;
    height: 82%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &-content {
      flex: 1;
    }
    &-item {
      display: flex;
      height: 0.33rem;
      line-height: 0.33rem;
      font-size: 0.14rem;
      color: #9EC7FF;
      margin: 0 0.15rem;
      padding-left: 0.12rem;
      &-flex {
        flex: 1;
      }
      &-right {
        width: 1.4rem;
        text-align: center;
      }
      &-head {
        height: 0.4rem;
        line-height: 0.4rem;
        font-weight: 500;
        font-size: 0.17rem;
        color: #20CEFA;
      }
    }
  }
  .scheme-box-item:nth-child(even) {
      background: rgba(18,39,153,0.3);
  }
}
</style>