<template>
  <div class="model-box" ref="model-box">
    <div class="gauge-box">
      <Chart :option="linkOptions" style="height: 100%" />
      <div class="gauge-box-ball" :style="{ width: ballH +'px', height: ballH+'px', lineHeight: ballH+'px'  }"> {{ options.riskScore || '--' }} </div>
    </div>
    <div style="height: 1%"></div>
    <div class="msg-box">
      <div class="msg-box-left">设备工况
        <div class="badge-box badge-excellent" v-if="options.equipmentCondition == 1">优秀</div>
        <div class="badge-box badge-primary" v-else-if="options.equipmentCondition == 2">良好</div>
        <div class="badge-box badge-warning" v-else-if="options.equipmentCondition == 3">一般</div>
        <div class="badge-box badge-error" v-else>差</div>
      </div>
      <div class="msg-box-right">
        是不是防护等级越高就越好?答案是否定的。防护等级越高，用电设备散热越差，它的温升也就越高，继而用电
      </div>
    </div>
    <div class="condition">
      <div class="condition-item" v-for="item,index in conditionList" :key="index">
        <div class="condition-item-num">{{  options[item.keyName] instanceof Object ? (options[item.keyName].count || options[item.keyName].name) : ( options[item.keyName] || '--' ) }}{{ item.unit }}
          <div class="badge-box badge-primary" v-if="options[item.keyName] && options[item.keyName].num == 2">良好</div>
          <div class="badge-box badge-excellent" v-else-if="options[item.keyName] && options[item.keyName].num == 1">优</div>
          <div class="badge-box badge-warning" v-else-if="options[item.keyName] && options[item.keyName].num == 3">一般</div>
          <div class="badge-box badge-error" v-else-if="options[item.keyName] && options[item.keyName].num == 4">差</div>
        </div>
        <div class="condition-item-name">{{ item.name }}</div>
      </div>
    </div>
    <div class="history">
      <div class="history-top">
        <div class="history-top-name">
          历史出险概率系数
          <span>{{ options.historySimpleMeanIndemnity || '--' }}</span>
        </div>
        <div class="history-top-information">
          <div class="history-top-information-item">出险次数 {{ options.outRiskNumber || '--' }}</div>
          <div class="history-top-information-item">设备台数 {{ options.equipmentNumber || '--' }}</div>
          <div class="history-top-information-item">保险期限 {{ options.insuranceTerm || '--' }}</div>
        </div>
      </div>
      <div class="history-top history-down">
        <div class="history-top-name history-down-name">
          历史单均赔款(非玻璃)
          <span>{{ options.historyOutRiskChanceCoefficient || '--' }}</span>
        </div>
        <div class="history-top-information">
          <div class="history-top-information-item history-down-information-item">累计出险金额 {{ options.accumulatedRiskAmount || '--' }}</div>
          <div class="history-top-information-item history-down-information-item">累计案件量 {{ options.cumulativeCaseload || '--' }}</div>
        </div>
      </div>
    </div>
    <div class="conclusion">
      <div>机手变更频率：{{ options.jsChangeFrequency || '--' }}</div>
      <div>安全培训频率：{{ options.safetyTrainingFrequency || '--' }}</div>
    </div>
    <div class="conclusion">
      <div class="conclusion-left">风险管控方法</div>
      <div class="conclusion-right">{{ options.riskControlMethod || '--' }}</div>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.initChart(v.riskScore || []);
        });
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      linkOptions: {},
      ballH: 0,
      pointerData: 40,
      conditionList: [
        {
          num: '11',
          name: '月平均工时(小时)',
          status: '1',
          keyName: 'averageMonthlyWorkingHours'
        },
        {
          num: '国产',
          name: '设备品牌',
          status: '2',
          keyName: 'equipmentBrand'
        },
        {
          num: 11,
          name: '机龄情况',
          status: '3',
          keyName: 'machineAge'
        },
        {
          num: '50%',
          name: '开工率',
          status: '4',
          keyName: 'rateOperation',
          unit: '%'
        },
        {
          num: '11',
          name: '日平均工时(小时)',
          status: '1',
          keyName: 'averageWorkingHoursPerDay'
        },
        {
          num: '50%',
          name: '异常工时占比',
          status: '1',
          keyName: 'abnormalHourThan',
          unit: '%'
        },
        {
          num: '11',
          name: '日均警报数',
          status: '1',
          keyName: 'dailyAlarmNumber'
        },
        {
          num: '国产',
          name: '养护周期内保养设备数',
          status: '1',
          keyName: 'maintainCycleUpkeepNumber'
        },
        {
          num: '7%',
          name: '养护周期内保养率',
          status: '1',
          keyName: 'maintainCycleUpkeepThan',
          unit: '%'
        },
      ]
    };
  },
  created() {
    this.$nextTick(() => {
      // this.initChart();
    });
  },
  methods: {
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initChart(value) {
      let name = "统计";
      var scale = this.getSize(0.3, 2);
      let boxHeight = this.$refs["model-box"].offsetHeight * 0.15;
      this.ballH = boxHeight * 0.68;
      this.linkOptions = {
        // title: {
        //   // text: '{a|' + value + '}{c|%}',
        //   text: "{a|" + value + "%}{c|\n" + name + "}",
        //   x: "center",
        //   y: "center",
        //   textStyle: {
        //     rich: {
        //       a: {
        //         fontSize: scale * 1,
        //         color: "#29EEF3",
        //       },

        //       c: {
        //         fontSize: scale * 1,
        //         color: "#fff",
        //         // padding: [5,0]
        //       },
        //     },
        //   },
        // },
        series: [
          {
            type: "pie",
            name: "外圆环",
            radius: ["97%", "99%"],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                color: "rgba(253, 144, 49, 1)",
              },
            },
            label: {
              show: false,
            },
            data: [100],
          },
          {
            type: "gauge",
            radius: "90%",
            z: 1,
            startAngle: 90,
            endAngle: 450,
            splitNumber: 60,
            splitLine: {
              show: true,
              length: boxHeight * 0.06,
              distance: 0 - (boxHeight * 0.06),
              lineStyle: {
                color: "RGBA(4, 13, 55, 1)",
                width: 1,
              },
            },
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
            // 仪表盘的线，颜色值为一个数组
            axisLine: {
              show: true,
              // 两端是否设置为圆角；在5.0之后的版本有效
              roundCap: false,
              lineStyle: {
                width: boxHeight * 0.06,
                shadowColor: false, //默认透明
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 0,
                opacity: 1,
                color: [
                  [
                    value / 100,
                    {
                      x: 0,
                      y: 0,
                      x1: 1,
                      y1: 0,
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(241, 97, 69, 1)",
                        },
                        {
                          offset: 1,
                          color: "rgba(255, 150, 47, 1)",
                        },
                      ],
                    },
                  ],
                  [1, "RGBA(80, 66, 85, 1)"],
                ],
              },
            },
            // 仪表盘刻度标签
            axisLabel: {
              show: false,
            },
            // 刻度
            axisTick: {
              show: false,
            },
            data: [value],
          },
          // {
          //   type: "pie",
          //   name: "内环",
          //   radius: ["74%", "76%"],
          //   hoverAnimation: false,
          //   clockWise: false,
          //   itemStyle: {
          //     normal: {
          //       color: "#304867",
          //     },
          //   },
          //   label: {
          //     show: false,
          //   },
          //   data: [100],
          // },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 95%;
  padding: 2%;
  .badge-box {
    position: absolute;
    top: 0;
    right: -0.2em;
    transform: translate(100%, -50%);
    width: 0.3rem;
    height: 0.16rem;
    line-height: 0.16rem;
    font-size: 0.14rem;
    font-family: auto;
    color: #fff;
    text-align: center;
    border-radius: 0.04rem;
  }
  .badge-primary {
    background: #459EF1;
  }
  .badge-excellent {
    background: rgba(42, 194, 88, 1);
  }
  .badge-warning {
    background: rgba(241, 186, 69, 1);
  }
  .badge-error {
    background: rgba(241, 98, 69, 1);
  }
  .gauge-box {
    position: relative;
    height: 15%;
    &-ball {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      color: #F36842;
      font-size: 0.26rem;
      text-align: center;
        font-family: 'ZhanKuQingKeHuangYouTi';
      background: rgba(3,12,30,0.6);
      border: 0.01rem solid #F46D40;
      box-shadow: inset 0px 0px 10px rgba(244, 109, 64, 1);
    }
  }
  .msg-box {
    display: flex;
    font-size: 0.16rem;
    color: #fff;
    padding: 0.16rem;
    &-left {
      position: relative;
      margin-right: 0.1rem
    }
    &-right {
      flex: 1;
      text-indent: 2em;
    }
  }
  .condition {
    display: flex;
    flex-wrap: wrap;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33%;
      margin-bottom: 0.22rem;
      &-num {
        position: relative;
        text-align: center;
        font-family: 'ZhanKuQingKeHuangYouTi';
        font-size: 0.22rem;
        color: #FFFD37;
        margin-bottom: 0.1rem;
      }
      &-name {
        font-size: 0.16rem;
        text-align: center;
        color: #FFF;
      }
    }
  }
  .history{
    height: 22%;
    padding: 2%;
    background-image: url('../../../../assets/images/bigScreen/customerRecord/historyBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 1%;
      height: 50%;
    }
    &-top {
      padding-bottom: 1%;
      &-name {
        font-size: 0.16rem;
        color: #FFFFFF;
        span {
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.22rem;
          color: #18D2CE;
          margin-left: 0.16rem;
        }
      }
      &-information{
        display: flex;
        justify-content: space-between;
        &-item{
          position: relative;
          font-size: 0.14rem;
          color: #C9ECEB;
          padding-left: 0.1rem;
        }
        &-item::before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          width: 0.06rem;
          height: 0.06rem;
          border-radius: 50%;
          background: #18D2CE;

        }
      }
    }
    &-down {
      border-top: 0.01rem solid #043D80;
      padding-bottom: 0;
      padding-top: 1%;
      &-name {
        span {
          color: #1560EA;
        }
      }
      &-information{
        &-item{
          flex: 1;
        }
        &-item::before {
          background: #1560EA;
        }
      }
    }
  }
  .conclusion {
    display: flex;
    justify-content: space-between;
    font-size: 0.16rem;
    color: #fff;
    // margin-top: 0.14rem;
    margin-top: 2%;
    padding: 0 2%;
    >div {
      flex: 1;
    }
    &-left {
      flex: none !important;
      margin-right: 0.16rem;
    }
  }
}
</style>