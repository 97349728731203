<template>
  <div class="model-box">
    <div ref="service-bar">
      <div class="information">
        <div class="information-left">
          <div class="information-left-text">理赔案件数量</div>
          <div class="information-left-num">
            {{ options.claimsSettledNumber || "--" }} <span>起</span>
          </div>
        </div>
        <div class="information-right">
          <div class="information-right-text">赔偿金额</div>
          <div class="information-right-num">
            {{ options.compensationAmount || "--" }} <span>元</span>
          </div>
        </div>
      </div>
      <div style="height: 77%">
        <Chart :option="pieOptions1" style="height:100%" />
      </div>
    </div>
    <div>
      <div style="display: flex; align-items: flex-end; height: 0.8rem;">
        <header-name title="损失类型占比" />
      </div>
      <div style="height: calc(100% - 0.8rem);width:90%;margin:auto;">
        <Chart :option="pieOptions2" style="height: 100%;" />
      </div>
    </div>
    <div>
      <div style="display: flex; align-items: flex-end; height: 0.8rem">
        <header-name title="出险类型占比" />
      </div>
      <div style="height: calc(100% - 0.8rem);width:90%;margin:auto;">
        <Chart :option="pieOptions3" style="height: 100%;" />
      </div>
    </div>
    <div style="padding-top: 0">
      <div style="display: flex; align-items: center; height: 0.8rem">
        <header-name title="赔付案件量分布趋势" />
      </div>
      <Chart :option="lineOptions" style="height: calc(100% - 0.8rem);" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import HeaderName from "@/views/BigScreenDisplay/components/HeaderName";
import * as echarts from "echarts";
export default {
  components: { Chart, HeaderName },
  data() {
    return {
      pieOptions1: {},
      pieOptions2: {},
      pieOptions3: {},
      lineOptions: {},
      chartHeight: 0
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(() => {
          this.pieOptions1 = this.initCharts(
            v.compensationAmountIntervalProportion || []
          );
          this.pieOptions2 = this.initCharts(v.lossTypeProportion.map(val=> ({
            ...val,
            value: val.ratio
          })) || [], 'none');
          this.pieOptions3 = this.initCharts(v.outRiskTypeProportion.map(val=> ({
            ...val,
            value: val.ratio
          })) || [], 'none');
          this.initChart(
            v.claimCaseNumberDistribution || []
          );
        });
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    // this.init()
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      this.$nextTick(() => {
        // const clientWidth = this.$refs['distributionBox'].offsetWidth / 2;
        // const clientHeight = this.$refs['distributionBox'].offsetHeight / 2;
        // this.itemWidth = clientWidth > clientHeight ? clientHeight : clientWidth;
        // this.boxH = this.$refs["model-box"].offsetHeight;
        this.initCharts();
        this.initChart();
      });
      // })
    },
    getSize(res, type) {
      const clientWidth = this.$refs["service-bar"].offsetWidth;
      const clientHeight = this.$refs["service-bar"].offsetHeight;
      if (!clientWidth) return;
      this.chartHeight = clientHeight * 0.85;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initCharts(data = [], type) {
      var scale = this.getSize(0.3, 2);
      var rich = {
        yellow: {
          color: "#ffc72b",
          fontSize: scale * 1.4,
          fontFamily: "ZhanKuQingKeHuangYouTi",
          align: "left",
        },
        total: {
          color: "#ffc72b",
          fontSize: scale * 1.6,
          align: "left",
        },
        white: {
          color: "#fff",
          align: "left",
          fontSize: scale * 1.2,
          padding: [0.5, 0],
        },
        blue: {
          color: "#49dff0",
          fontSize: 1 * scale,
          align: "left",
        },
      };
      return {
        legend: {
          top: "center",
          textStyle: {
            color: "#fff",
            fontSize: scale * 1,
          },
          orient: "vertical",
          itemWidth: scale * 1,
          itemHeight: scale * 1,
          right: scale * 1,
          textStyle: {
            rich: {
              name: {
                width: scale * 1,
                fontSize: scale * 1.4,
                color: "#fff",
              },
            },
          },
          formatter: function (params) {
            return `{name|${params}}`;
          },
          data: data.map((val) => val.name),
        },
        grid: {
          bottom: "26%",
        },
        series: [
          {
            name: "1",
            type: "pie",
            radius: ["0%", "50%"],
            center: ["38%", "45%"],
            color: [
              "#116ec9",
              "#770bd8",
              "#d0c512",
              "#1caddb",
              "#128aff",
              "#6f81da",
              "#00ffb4",
            ],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  color: "#ddd",
                  width: scale * 5,
                  formatter: function (params, ticket, callback) {
                    return (
                      "{white|" +
                      params.name +
                      "}\n{hr|}\n{yellow|" +
                     (type !== 'none' ? (params.value +
                      "/") : '') +
                      params.data.ratio +
                      "% }"
                    );
                  },
                  rich: rich,
                  // alignTo: 'labelLine',
                  // position: "outside",
                  position: 'outer',
                  alignTo: 'labelLine',
                  bleedMargin: 5
                },
                labelLine: {
                  show: true,
                  lineStyle: {
                    type: "dashed",
                    color: "#fff",
                    width: scale * 0.1
                  },
                  length2: scale * 4
                },
              },
            },
            data: data,
          },
        ],
      };
    },
    initChart(datalist) {
      const sizeHNum = this.getSize(0.3, 2);
      let cities = datalist.map(val=> val.month);
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            color: "#fff",
          },
        },
        grid: {
          borderWidth: 0,
          top: "18%",
          bottom: "26%",
          left: "16%",
          right: "22%",
          color: "#fff",
        },
        legend: {
          top: "1%",
          right: "3%",
          data: ["数量", "金额"],
          icon: "pin",
          textStyle: {
            color: "#ffffff",
            fontSize: sizeHNum * 1.2,
          },
        },

        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                color: "rgba(9, 54, 109, 1)",
              },
            },
            axisTick: {
              //刻度线
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                fontWeight: 300,
                color: "#fff",
              },
            },
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                let valueArr = [];
                if (value.length > 4) {
                  let str1 = value.slice(0, 5);
                  let str2 = value.slice(5);
                  valueArr = [str1, str2];
                } else {
                  valueArr = [value];
                }

                return valueArr.join("");
              },
            },
            data: cities,
          },
        ],
        yAxis: [
          {
            type: "value",
            position: "left",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .2)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              fontSize: 12,
              fontWeight: 300,
              color: "#fff",
            },
            splitArea: {
              show: false,
            },
            // max: max_left,
            // interval: interval_left,
            splitNumber: 5,
          },
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .2)",
              },
            },
            position: "right",
            axisLabel: {
              formatter: "{value}",
              fontSize: 12,
              fontWeight: 300,
              color: "#fff",
            },
            splitNumber: 5,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barMaxWidth: sizeHNum * 2,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "#1BDFFC" },
                { offset: 0, color: "#1251D2" },
              ]),
              borderRadius: [6, 6, 0, 0],
              label: {
                show: false,
                position: "inside",
                formatter: function (p) {
                  return p.value > 0 ? p.value : "";
                },
              },
            },
            data: datalist.map(val=> val.amount),
          },
          {
            name: "数量",
            yAxisIndex: 1,
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFFD37",
              borderRadius: 0,
              label: {
                show: false,
                position: "top",
                formatter: function (p) {
                  return p.value > 0 ? p.value + "%" : "";
                },
              },
            },
            lineStyle: {
              width: sizeHNum *  0.2
            },
            data: datalist.map(val=> val.number),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  overflow: hidden;
  display: flex;
  > div {
    flex: 1;
    height: 100%;
    padding-top: 1%;
    margin: 0 1%;
  }
  .information {
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
    height: 23%;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      height: 100%;
      padding: 0.16rem;
      background-image: url("../../../../assets/images/bigScreen/customerRecord/quantity.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        font-size: 0.14rem;
        color: #fff;
      }
      &-num {
        font-size: 0.22rem;
        color: #18d2ce;
        font-family: "ZhanKuQingKeHuangYouTi";
        span {
          font-family: auto;
          font-size: 0.14rem;
          color: #fff;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      height: 100%;
      padding: 0.16rem;
      background-image: url("../../../../assets/images/bigScreen/customerRecord/quantity.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-text {
        font-size: 0.14rem;
        color: #fff;
      }
      &-num {
        font-size: 0.22rem;
        color: #1560ea;
        font-family: "ZhanKuQingKeHuangYouTi";
        span {
          font-family: auto;
          font-size: 0.14rem;
          color: #fff;
        }
      }
    }
  }
}
</style>