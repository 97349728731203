<template>
  <div class="model-box" ref="model-box">
    <div class="pop-info">
      <div class="pop-info-top">
        <div class="pop-info-top-name">{{ options && options.customerInfo ? options.customerInfo.name : '--' || '' }}</div>
        <div class="pop-info-top-sj">{{ options && options.customerInfo ? options.customerInfo.phone : '--' || '' }}</div>
        <div class="pop-info-top-a">{{ options && options.customerInfo ? options.customerInfo.address : '--' || '' }}</div>
        <!-- <div class="pop-info-top-a">{{ options.customerInfo ? options.customerInfo.name : '--' || '' }}</div> -->
      </div>
      <div class="pop-info-lower">
        <div class="pop-info-lower-item">
          <div>总资产</div>
          <div>{{ options && options.assetInfo ? options.assetInfo.totalAssets : '--' || '' }}</div>
        </div>
        <div class="pop-info-lower-item">
          <div>设备总价值	</div>
          <div>{{ options && options.assetInfo ? options.assetInfo.totalValueOfEquipment : '--' || '' }}</div>
        </div>
        <div class="pop-info-lower-item">
          <div>总保额	</div>
          <div>{{ options && options.assetInfo ? options.assetInfo.totalSumInsured : '--' || '' }}</div>
        </div>
        <div class="pop-info-lower-item">
          <div>保费规模	</div>
          <div>{{ options && options.assetInfo ? options.assetInfo.premiumScale : '--' || '' }}</div>
        </div>
      </div>
    </div>
    <img class="model-box-r" :style="{height: boxH + 'px'}" src="../../../../assets/images/bigScreen/customerRecord/customerIcon.png" alt="" srcset="">
    <div class="pop-info">
       <!-- style="align-items: flex-end" -->
      <div class="pop-info-right">
        <img class="pop-info-right-icon" v-if="options && options.customerTypeInfo && options.customerTypeInfo.customerType == 1" src="../../../../assets/images/bigScreen/customerRecord/signIcon1.png" alt="">
        <img class="pop-info-right-icon" v-else-if="options && options.customerTypeInfo && options.customerTypeInfo.customerType == 2" src="../../../../assets/images/bigScreen/customerRecord/signIcon1.png" alt="">
        <img class="pop-info-right-icon" v-else src="../../../../assets/images/bigScreen/customerRecord/signIcon1.png" alt="">
        <div class="pop-info-right-information">
          <div>
            <div>期满</div>
            <div class="pop-info-right-information-num1">{{ options && options.customerTypeInfo ? options.customerTypeInfo.expire : '--' || '' }}</div>
          </div>
          <div>
            <div>赔付率</div>
            <div class="pop-info-right-information-num2">{{ options && options.customerTypeInfo ? options.customerTypeInfo.lossRatio : '--' || '' }}%</div>
          </div>
        </div>
        <div class="pop-info-right-caption">
          <!-- {{ options.customerTypeInfo ? options.customerTypeInfo.premiumScale : '--' || '' }} -->
          高保费，超高赔付
        </div>
        <div class="pop-info-right-msg">{{ options && options.customerTypeInfo ? options.customerTypeInfo.customerTypeSuggestion : '--' || '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxH: 0
    }
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    options: {
      handler(v) {
        this.init();
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
 methods: {
    init() {
      this.$nextTick(() => {
        this.boxH = this.$refs["model-box"].offsetHeight *0.94;
      });
      // })
    },
  }
}
</script>

<style lang="scss" scoped>
.model-box {
  height: 100%;
  padding: 2% 5%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  .pop-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    &-lower {
      width: 2.7rem;
      height: 2.55rem;
      color: #fff;
      padding-top: 1.2rem;
      padding-left: 0.17rem;
      padding-right: 0.5rem;
      background-image: url('../../../../assets/images/bigScreen/customerRecord/propertyInfo.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-item {
        display: flex;
        justify-content: space-between;
        font-size: 0.14rem;
        margin-bottom: 0.1rem;
      }
    }
    &-top {
      width: 2.7rem;
      height: 2.55rem;
      color: #fff;
      padding-top: 0.94rem;
      padding-left: 0.17rem;
      padding-right: 0.5rem;
      background-image: url('../../../../assets/images/bigScreen/customerRecord/userInfo.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-name {
        font-size: 0.2rem;
        margin-bottom: 0.12rem;
      }
      &-sj {
        font-size: 0.14rem;
        margin-bottom: 0.12rem;
      }
      &-a {
        font-size: 0.14rem;
        margin-bottom: 0.02rem;
      }
    }
    &-right {
      width: 2.7rem;
      height: 4.76rem;
      padding-top: 1.2rem;
      padding-left: 0.6rem;
      padding-right: 0.2rem;
      background-image: url('../../../../assets/images/bigScreen/customerRecord/userType.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      &-icon {
        display: block;
        margin: auto;
        width: 0.88rem;
        height: 1.12rem;
      }
      &-information {
        display: flex;
        justify-content: space-between;
        margin-top: 0.24rem;
        margin-bottom: 0.12rem;
        div {
          text-align: center;
          color: #fff;
          font-size: 0.14rem;
        }
        &-num1 {
          font-size: 0.2rem !important;
          margin-top: 0.1rem;
          color: #1560EA !important;
          font-family: 'ZhanKuQingKeHuangYouTi';
        }
        &-num2 {
          font-size: 0.2rem !important;
          color: #18D2CE !important;
          margin-top: 0.1rem;
          font-family: 'ZhanKuQingKeHuangYouTi';
        }
      }
      &-caption {
        text-align: center;
        width: 1.8rem;
        height: 0.52rem;
        line-height: 0.52rem;
        margin: auto;
        font-size: 0.14rem;
        color: #D5DDFF;
        background-image: url('../../../../assets/images/bigScreen/customerRecord/captionIcon.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      &-msg {
        margin-top: 0.14rem;
        font-size: 0.14rem;
        color: #FFFFFF;
        line-height: 0.18rem;
      }
    }
  } 
}
</style>