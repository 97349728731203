<template>
  <div class="model-box">
    <div class="headline">
      <div>年份</div>
      <div>保费</div>
    </div>
    <div class="content">
      <div v-for="item,index in options.insuranceHistory" :key="index">
        <div>{{ item.year || '--' }}</div>
        <div>{{ item.premium || '--' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  height: 73%;
  padding: 2%;
  overflow: hidden;
  .headline {
    width: 0.64rem;
    display: flex;
    flex-direction: column;
    font-size: 0.14rem;
    color: #FEFEFE;
    border-right: 0.01rem solid #263F90;
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .content {
    display: flex;
    overflow-x: auto;
    >div {
      display: flex;
      flex-direction: column;
      font-size: 0.14rem;
      color: #FEFEFE;
      >div {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 0 0.12rem;
      }
    }
  }
}
</style>